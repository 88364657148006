<template>
    <div class="app-container">
        <div class="filter-container" style="display: flex;">
            <el-button class="filter-item" size="small" type="primary" @click="$router.go(-1)">返回</el-button>

            <el-date-picker class="filter-item" size="small" v-model="dateArr" type="daterange" value-format="yyyy-MM-dd" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" style="display: inline-flex;"></el-date-picker>

            <el-input class="filter-item" size="small" v-model="listQuery.keyword" placeholder="请输入姓名/手机号码" style="width: 220px;" clearable/>

            <el-input v-if="!listQuery.shop_id" class="filter-item" size="small" v-model="listQuery.shop_name" placeholder="请输入渠道" style="width: 220px;" clearable/>

            <el-select class="filter-item" size="small" v-model="listQuery.verify_status" placeholder="验证状态" style="width: 120px" clearable>
                <el-option label="待验证" :value="0" />
                <el-option label="有效" :value="1" />
                <el-option label="无效" :value="2" />
            </el-select>

            <el-button class="filter-item" size="small" type="primary" @click="handleFilter">搜索</el-button>

            <download-excel :fields="json_fields" :fetch="handleExport" type="xls" name="农行订单.xls" worksheet="农行订单">
                <el-button type="success" size="small" :loading="exportLoading">导出</el-button>
            </download-excel>
        </div>

        <div class="filter-container" v-loading="listLoading" element-loading-text="Loading">
            <el-row :gutter="20">
                <el-col :sm="6">
                    <el-card class="box-card">
                        <div class="value" style="color:#409EFF;">{{ sum.pay_money_sum }}</div>
                        <div class="subtitle">购买总金额</div>
                    </el-card>
                </el-col>

                <el-col :sm="6">
                    <el-card class="box-card">
                        <div class="value" style="color:#67C23A;">{{ sum.reward_balance_sum }}</div>
                        <div class="subtitle">赠送总金额</div>
                    </el-card>
                </el-col>

                <el-col :sm="6">
                    <el-card class="box-card">
                        <div class="value" style="color:#E6A23C;">{{ sum.commission_sum }}</div>
                        <div class="subtitle">渠道总分佣</div>
                    </el-card>
                </el-col>

                <el-col :sm="6">
                    <el-card class="box-card">
                        <div class="value" style="color:#F56C6C;">{{ sum.refund_money_sum }}</div>
                        <div class="subtitle">退款总金额</div>
                    </el-card>
                </el-col>
            </el-row>
        </div>

        <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
            <el-table-column label="ID" width="80" align="center">
                <template slot-scope="scope">
                    {{ scope.row.id }}
                </template>
            </el-table-column>

            <el-table-column label="用户" min-width="110" align="center">
                <template slot-scope="scope">
                    <div style="display: flex;flex-direction:column;align-items:center;text-align:center;">
                        <img v-if="scope.row.user&&scope.row.user.avatar" :src="scope.row.user.avatar" class="user-avatar">
                        <span>{{ scope.row.user.nickname }}</span>
                    </div>
                </template>
            </el-table-column>

            <el-table-column label="姓名" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.user.name }}
                </template>
            </el-table-column>

            <el-table-column label="手机号码" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.user.phone }}
                </template>
            </el-table-column>

            <el-table-column label="购买时间" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.pay_time }}
                </template>
            </el-table-column>

            <el-table-column label="购买金额" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.pay_money }}
                </template>
            </el-table-column>

            <el-table-column label="赠送余额" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.reward_balance }}
                </template>
            </el-table-column>

            <el-table-column label="渠道分佣" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.commission }}
                </template>
            </el-table-column>

            <el-table-column label="推广渠道" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.shop.shop_name }}
                </template>
            </el-table-column>

            <el-table-column label="订单号" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.order_no }}
                </template>
            </el-table-column>

            <el-table-column label="验证状态" min-width="110" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.verify_status === 0" type="info">待验证</el-tag>
                    <el-tag v-else-if="scope.row.verify_status === 1" type="success">有效</el-tag>
                    <el-tag v-else-if="scope.row.verify_status === 2" type="danger">无效</el-tag>
                </template>
            </el-table-column>

            <el-table-column v-if="user.user.type === 'admin'" label="操作" fixed="right" align="center" width="150" class-name="small-padding fixed-width">
                <template slot-scope="scope">
                    <el-button v-if="scope.row.pay_status === 1 && scope.row.verify_status === 0" type="success" size="mini" @click="handleChannelVerify(scope.row)">验证</el-button>
                </template>
            </el-table-column>
        </el-table>

        <div class="pagination-container">
            <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>

        <!-- 验证 -->
        <el-dialog title="验证" :visible.sync="dialogVerifyVisible" width="60%" :close-on-click-modal="false">
            <el-form ref="formVerify" :rules="rulesVerify" :model="formVerify" label-width="150px">
                <el-form-item label="验证状态" prop="verify_status">
                    <el-radio-group v-model="formVerify.verify_status" size="small">
                        <el-radio :label="1" border>有效</el-radio>
                        <el-radio :label="2" border>无效</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogVerifyVisible = false">取消</el-button>

                <el-button type="primary" @click="saveVerifyData" :loading="btnLoading">确定</el-button>
            </div>
        </el-dialog>
        <!-- 验证 -->
    </div>
</template>

<script>
    import request from "@/utils/request";
    import { mapGetters } from "vuex";
    import downloadExcel from "vue-json-excel";

    export default {
        components: { downloadExcel },
        data() {
            return {
                dateArr: null,
                pickerOptions: {
                    shortcuts: [{
                        text: "最近一周",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit("pick", [start, end]);
                        }
                    }, {
                        text: "最近一个月",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit("pick", [start, end]);
                        }
                    }, {
                        text: "最近三个月",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit("pick", [start, end]);
                        }
                    }]
                },
                list: null,
                total: null,
                listLoading: true,
                listQuery: {
                    page: 1,
                    limit: 10,
                    activities_id: "",
                    start_time: "",
                    end_time: "",
                    keyword: "",
                    shop_name: "",
                    shop_id: "",
                    verify_status: "",
                },
                sum: {
                    pay_money_sum: 0,
                    reward_balance_sum: 0,
                    commission_sum: 0,
                    refund_money_sum: 0,
                },
                // 验证
                dialogVerifyVisible: false,
                btnLoading: false,
                formVerify: {
                    order_no: '',
                    verify_status: '',
                },
                rulesVerify: {
                    verify_status: [{ required: true, message: '验证状态不能为空', trigger: 'change' }],
                },
                // 导出农行订单
                exportLoading: false,
                json_fields: {
                    用户姓名: "user.name",
                    手机号码: {
                        field: "user.phone",
                        //自定义回调函数
                        callback: (value) => {
                            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
                        },
                    },
                    购买时间: {
                        field: "pay_time",
                        //自定义回调函数
                        callback: (value) => {
                            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
                        },
                    },
                    购买金额: "pay_money",
                    赠送余额: "reward_balance",
                    渠道分佣: "commission",
                    推广渠道: "shop.shop_name",
                    订单号: {
                        field: "order_no",
                        //自定义回调函数
                        callback: (value) => {
                            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
                        },
                    },
                },
            };
        },
        created() {
            this.listQuery.activities_id = parseInt(this.$route.query.activities_id || 0);
            this.listQuery.shop_id = parseInt(this.$route.query.shop_id || 0);
            this.getList();
        },
        computed: {
            ...mapGetters(["schools", "school_id","user"]),
        },
        watch: {
            dateArr(newVal, oldVal) {
                if (newVal) {
                    this.listQuery.start_time = newVal[0];
                    this.listQuery.end_time = newVal[1];
                } else {
                    this.listQuery.start_time = "";
                    this.listQuery.end_time = "";
                }
            },
        },
        methods: {
            getList() {
                this.listLoading = true;
                request({
                    url: "/api/backend/agriculturalBankActivities/orderRecord",
                    method: "get",
                    params: this.listQuery
                }).then(response => {
                    this.list = response.data.list.data;
                    this.total = response.data.list.total;
                    this.sum = response.data.sum;
                    this.listLoading = false;
                });
            },
            handleFilter() {
                this.listQuery.page = 1;
                this.getList();
            },
            handleSizeChange(val) {
                this.listQuery.limit = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.listQuery.page = val;
                this.getList();
            },
            // 验证
            handleChannelVerify(row){
                this.formVerify.order_no = row.order_no
                this.formVerify.verify_status = ''
                this.btnLoading = false
                this.dialogVerifyVisible = true
                this.$nextTick(() => {
                    this.$refs['formVerify'].clearValidate() //清除校验结果
                })
            },
            saveVerifyData(){
                this.$refs['formVerify'].validate(valid => {
                    if (valid) {
                        this.btnLoading = true
                        request({
                            url: '/api/backend/agriculturalBankActivities/orderVerify',
                            method: 'post',
                            data: this.formVerify
                        }).then(() => {
                            this.btnLoading = false
                            this.dialogVerifyVisible = false
                            this.$message({
                                type: 'success',
                                message: '操作成功'
                            });
                            this.getList()
                        }).catch(() => {
                            this.btnLoading = false
                        })
                    }
                })
            },
            // 导出农行订单
            handleExport() {
                let listQuery = JSON.parse(JSON.stringify(this.listQuery))
                listQuery.page = 1
                listQuery.limit = this.total
                this.exportLoading=true;
                return request({
                    url: "/api/backend/agriculturalBankActivities/orderRecord",
                    method: "get",
                    params: listQuery,
                    timeout: 10 * 60 * 1000,
                }).then((result) => {
                    if (result.data.list.data.length <= 0) {
                        this.$message({
                            type: "warning",
                            message: "暂无记录",
                        });
                        return;
                    }
                    return result.data.list.data;
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.exportLoading=false;
                });
            },
        }
    };
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
    .user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 6px;
    }
    .box-card {
        text-align: center;
        .value {
            color: #546DFE;
            font-weight: bold;
            font-size: 26px;
        }
        .subtitle {
            font-size: 12px;
            color: gray;
            margin-top: 6px;
        }
    }
    .el-row {
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .el-col {
        margin-bottom: 20px;
    }
</style>
